import { toggle } from './functions';

export default function header() {
  const layout = document.querySelector('.layout');
  const openCourse = document.querySelectorAll('[data-open-course]');
  const closeCourse = document.querySelectorAll('[data-close-course]');
  const header = document.querySelector('.header');
  const headerBottom = document.querySelector('.header__bottom');
  const closeSearch = document.querySelector('.search__close');
  const openSearch = document.querySelector('.header__search');
  const search = document.querySelector('.search');
  const headerButtonMobileOpen = document.querySelector('.header__mobile-open');
  const headerButtonMobileClose = document.querySelector('.menu-mobile__menu-close');
  const headerMobileMenu = document.querySelector('.menu-mobile');
  const toTop = document.querySelector('.b-scrollTopButton');


  document.onclick = function (e) {
    // console.log(e.target==openCourse);

    if (e.target.className != 'header__course-button' && e.target.className != 'menu-mobile__course-button') {
      header.classList.remove('--active-popup');
    }
  };

  if (openCourse) {
    openCourse.forEach((button) => {
      button.onclick = function () {
        if (header.classList.contains('--active-popup')) {
          header.classList.remove('--active-popup');
          header.querySelector('.header__wrapper').classList.remove('--active')
        } else {
          header.classList.add('--active-popup');
          header.querySelector('.header__wrapper').classList.add('--active')

        }
      };
    });
  }

  if (closeCourse) {
    closeCourse.forEach((button) => {
      button.onclick = function () {
        // toggle(header);
        header.classList.remove('--active-popup');
      };
    });
  }

  openSearch.onclick = function () {
    search.classList.add('--active-popup');
    layout.classList.add('--active-popup');
    header.classList.remove('--active-popup');
    document.querySelector('body').classList.add('--no-scroll');
  };

  closeSearch.onclick = function () {
    toggle(search);
    toggle(layout);
    headerMobileMenu.classList.remove('open');
    document.querySelector('.searchForm').classList.remove('--active');
    header.querySelector('.header__wrapper').classList.remove('--active')

  };

  layout.onclick = function () {
    search.classList.remove('--active-popup');
    headerMobileMenu.classList.remove('open');
    header.classList.remove('--active-popup');
    document.querySelector('body').classList.remove('--no-scroll');
    header.querySelector('.header__wrapper').classList.remove('--active')
    search.classList.remove('--active-popup')
    layout.classList.remove('--active-popup');
    document.querySelector('.searchForm').classList.remove('--active');
  };

  // document.querySelector('main').onclick = function () {
  //   let activeElements = document.querySelectorAll('.--active-popup');

  //   if (activeElements && activeElements.length > 0) {
  //     activeElements.forEach((el) => {
  //       el.classList.contains('--active-popup') ? toggle(el) : '';
  //     });
  //   }
  // };

  let tempScroll = 0;

  window.onscroll = function () {
    let pageScroll = window.pageYOffset;

    toTop && pageScroll >= 500 ? toTop.classList.add('--active') : toTop.classList.remove('--active');

    if (header) {
      let headerHeight = header.clientHeight;

      if (pageScroll > headerHeight) {
        if (pageScroll > tempScroll) {
          header.classList.remove('--sticky');
          header.classList.add('--shadow');
          header.style.top = `${-headerHeight - 1}px`;
        } else {
          header.classList.add('--sticky');
          header.classList.remove('--shadow');

          header.style.top = '';
        }
      } else {
        header.classList.remove('--sticky');
        header.classList.remove('--shadow');
      }
      tempScroll = pageScroll;
    }

    header.classList.remove('--active-popup');

    if (headerBottom) {
      const anchors = headerBottom.querySelectorAll("a[href*='#']");

      if (anchors) {
        anchors.forEach((anchor) => {
          const windowScroll = window.scrollY + window.innerHeight / 2;
          const container = document.querySelector(anchor.getAttribute('href'));

          if (windowScroll > container.offsetTop && windowScroll < container.offsetTop + container.clientHeight) {
            anchor.parentNode.classList.add('--green');
          } else {
            anchor.parentNode.classList.remove('--green');
          }
        });
      }
    }
  };

  window.addEventListener('resize', () => {
    if (window.screen.width > 1515) {
      headerMobileMenu.classList.add('hidden');
      layout.classList.remove('--active-popup');
      document.querySelector('body').classList.remove('--no-scroll');
    } else {
      headerMobileMenu.classList.remove('hidden');

      if (header.classList.contains('--active-popup')) {
        layout.classList.add('--active-popup');
        headerMobileMenu.classList.add('open');
        layout.classList.add('--active-popup');
      }

      if (headerMobileMenu.classList.contains('open')) {
        layout.classList.add('--active-popup');
        document.querySelector('body').classList.add('--no-scroll');
      }
    }
  });

  if (headerBottom) {
    const anchors = headerBottom.querySelectorAll("a[href*='#']");
    if (anchors) {
      anchors.forEach((anchor) => {
        anchor.addEventListener('click', (e) => {
          e.preventDefault();
          document.querySelector(anchor.getAttribute('href')).scrollIntoView({
            behavior: 'smooth',
            // block: 'nearest',
          });
        });
      });
    }
  }

  // Меню на мобилке
  if (headerButtonMobileOpen && headerMobileMenu) {
    headerButtonMobileOpen.onclick = () => {
      headerMobileMenu.classList.add('open');
      layout.classList.add('--active-popup');
      document.querySelector('body').classList.add('--no-scroll');
      header.querySelector('.header__wrapper').classList.add('--active')
    };

    headerButtonMobileClose.onclick = () => {
      headerMobileMenu.classList.remove('open');
      layout.classList.remove('--active-popup');
      header.classList.remove('--active-popup');
      document.querySelector('body').classList.remove('--no-scroll');
      header.querySelector('.header__wrapper').classList.remove('--active')

    };
  }
}
