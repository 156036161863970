import { Fancybox, Carousel, Panzoom } from '@fancyapps/ui'; //Работает


export default function oNas () {

    const faqs = document.querySelectorAll(".m-faqs-container");

    faqs.forEach(function (element) {
        const faqMore = element.querySelector(".faq-content");
        const liBtn = element.querySelector(".b-faq_cont");

        element.querySelector(".b-faq_cont").addEventListener("click", function () {
            if (faqMore.classList.contains("open")) {
                element.querySelector(".faq-content").classList.remove("open");
                element.querySelector(".minus-stick").classList.remove("active");
                faqMore.style.maxHeight = null;
            } else {
                element.querySelector(".faq-content").classList.add("open");
                element.querySelector(".minus-stick").classList.add("active");
                faqMore.style.maxHeight = faqMore.scrollHeight + "px";
            }
        })
    })

    // const docs = document.querySelectorAll(".docs-img");
    //
    // docs.forEach(function (element) {
    //     element.addEventListener("click", function () {
    //         document.querySelector(".doc__pop-up__over").classList.add("open");
    //         document.body.style.overflow = 'hidden';
    //     })
    // })
    //
    // if(document.querySelector(".pu-doc__img")) {
    //     document.querySelector(".pu-doc__img").addEventListener("click", function () {
    //         document.querySelector(".doc__pop-up__over").classList.remove("open");
    //         // document.body.style.overflow = 'scroll';
    //     })
    //     document.querySelector(".pop-up-cross").addEventListener("click", function () {
    //         document.querySelector(".doc__pop-up__over").classList.remove("open");
    //         // document.body.style.overflow = 'scroll';
    //     })
    // }
    Fancybox.bind("[data-fancybox]");

    const steps = document.querySelectorAll(".post-card");

    steps.forEach(function (element) {
        element.querySelector(".card-content").addEventListener("click", function () {
            if(element.querySelector(".card-content").classList.contains("open")) {
                element.querySelector(".card-content").classList.remove("open");
            } else {
                element.querySelector(".card-content").classList.add("open");
            }
        })
    })
}