import $ from 'jquery'; //Работает

import Swiper, { Navigation } from 'swiper';
import { Fancybox, Carousel, Panzoom } from '@fancyapps/ui'; //Работает
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import "@fancyapps/ui/dist/carousel/carousel.css";
import 'swiper/css';
// import 'swiper/css/bundle';

export default function feedback() {

    if(document.querySelector('.feedback-swiper')) {
        const fSwiper = new Swiper('.feedback-swiper', {
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            spaceBetween: 20,
            slidesPerView: 3,
            breakpoints: {
                300: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                420: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 1
                },
                640: {
                    slidesPerView: 1
                },
                900: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 2
                },
                1200: {
                    slidesPerView: 3,
                },
            }
        })
    }

    const feedbacks = document.querySelectorAll('.feedback');

    feedbacks.forEach(function (e, index) {

        const photo = e.querySelectorAll(".photo");
        const video = e.querySelectorAll(".video");

        console.log(photo)

        photo.forEach(function (element) {
            console.log(index)
            Fancybox.bind(`[data-fancybox="gallery-${index}"]`, {
            });
        });
        video.forEach(function (element) {
            Fancybox.bind(`[data-fancybox="video-${index}"]`, {
            });
        })
    })

    const feedback = document.querySelectorAll('.feedback');

    feedback.forEach(e => {
        const feedbackText = e.querySelector('.feedback-text');
        const feedbackSpan = feedbackText.querySelector('span');
        const feedbackMore = e.querySelector('.feedback__more');
        const feedbackTitle = e.querySelector('.feedback-title a')

        console.log(feedbackSpan)

        if(feedbackText.clientHeight > 150) {
            feedbackMore.style.display = 'block'
            if(window.innerWidth <= 460) {
                feedbackSpan.style.maxHeight = '85px'
            } else {
                feedbackSpan.style.maxHeight = '120px'
            }
            feedbackSpan.style.display = 'block'

            feedbackMore.addEventListener('click', but => {
                if(!feedbackText.classList.contains('active')) {
                    feedbackText.classList.add('active')
                    feedbackTitle.classList.add('active')
                    if(window.innerWidth <= 460) {
                        feedbackSpan.style.maxHeight = '160px'
                    } else {
                        feedbackSpan.style.maxHeight = '190px'
                    }
                    feedbackMore.innerHTML = "Скрыть"
                } else {
                    feedbackText.classList.remove('active')
                    feedbackTitle.classList.remove('active')
                    if(window.innerWidth <= 460) {
                        feedbackSpan.style.maxHeight = '85px'
                    } else {
                        feedbackSpan.style.maxHeight = '120px'
                    }
                    feedbackSpan.scrollTo(0, 0)
                    feedbackMore.innerHTML = "Показать"
                }
            })
        } else {
            feedbackTitle.classList.add('show')
        }
    })
}


