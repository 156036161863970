export function ModalV2(modalEl) {
    this.element = modalEl;
    this.init();
}

ModalV2.prototype.init = function () {
    this.closeButton = this.element.querySelector('[data-close]');
    if (this.closeButton) {
        this.closeButton.addEventListener('click', (event) => {
            this.close();
        });
    }
    this.afterCloseFunctions = [];
    this.onWindowClick = this.onWindowClick.bind(this);
}

ModalV2.prototype.open = function () {
    this.element.style.display = 'flex';
    window.addEventListener('click', this.onWindowClick);
}

ModalV2.prototype.close = function () {
    this.element.style.display = 'none';
    window.removeEventListener('click', this.onWindowClick);
    this.onAfterClose();
}

ModalV2.prototype.onAfterClose = function () {
    console.log('onafterclose', this.afterCloseFunctions);
    this.afterCloseFunctions.forEach((func) => {
        func();
    });
}

ModalV2.prototype.onWindowClick = function (event) {
    if (event.target === this.element) {
        this.close();
    }
}

export function initModals(isInitButtons = true) {
    const modals = window.asteq.modals.list;
    const afterInitFunctions = window.asteq.modals.afterInitFuncitons;

    const dataAttrPopupName = 'data-modal-v2';
    const modalsList = document.querySelectorAll('[' + dataAttrPopupName + ']');
    modalsList.forEach((el) => {
        const modalName = el.getAttribute(dataAttrPopupName);
        if (!modals[modalName]) {
            modals[modalName] = new ModalV2(el);
        }
    });

    if (isInitButtons) {
        initModalButtons();
    }

    if (afterInitFunctions) {
        afterInitFunctions.forEach((func) => {
            setTimeout(() => {
                func();
            });
        });
    }
}

export function initModalButtons() {
    const dataAttrPopupButtonName = 'data-modal-v2-open';
    const popupButtons = document.querySelectorAll('[' + dataAttrPopupButtonName + ']');
    popupButtons.forEach((el) => {
        const modalName = el.getAttribute(dataAttrPopupButtonName);
        if(window.asteq.modals.list[modalName]) {
            el.addEventListener('click', () => {
                window.asteq.modals.list[modalName].open();
            });
        }
    });
}

export function initApi() {
    if (!window.asteq) {
        window.asteq = {};
    }
    window.asteq.modals = {};
    window.asteq.modals.list = {};
    window.asteq.modals.afterInitFuncitons = [];
}

export function initModalsV2() {
    if (!(window.asteq && window.asteq.modals)) {
        initApi();
    }

    setTimeout(() => {
        initModals();
    });
}
