export default class BirthdayTrigger {
    constructor(birthdayEl, relatedEls) {
        this.birthdayEl = birthdayEl;
        this.relatedEls = relatedEls;
        birthdayEl.addEventListener('change', this.onDateChange.bind(this));
    }

    onDateChange(e) {
        const before18 = new Date();
        before18.setFullYear(before18.getFullYear() - 18);
        console.log('d', before18)
        this.updateRelatedEls(before18 > new Date(e.target.value));
    }

    updateRelatedEls(status) {
        const toggledClass = 'hidden';
        const toggledAttr = 'data-control-required';
        const classFunc = status ? DOMTokenList.prototype.add : DOMTokenList.prototype.remove;
        const attrFunc = status ?  HTMLElement.prototype.removeAttribute : HTMLElement.prototype.setAttribute;
        console.log('w', status);
        this.relatedEls.forEach((el) => {
            classFunc.call(el.classList, toggledClass);
            const inputEl = el.querySelector('input');
            if(inputEl) {
                attrFunc.call(inputEl, toggledAttr, '');
            }
        });
    }

    static findAndInit(form) {
        const dateAttrName = 'data-birthday-date';
        const dateMajorityAttrName = 'data-birthday-majority';

        const birthdaysEls = form.querySelectorAll(`[${dateAttrName}]`);
        birthdaysEls.forEach((el) => {
            const id = el.getAttribute(dateAttrName);
            const relatedEls = form.querySelectorAll(`[${dateMajorityAttrName}]`);
            new this(el, relatedEls);
        });
    }
}
