import IMask from 'imask';
import { Ripple } from 'data-ripple';
import validate from 'validate.js';
import { toggle } from './functions';

export default function feedbackForm() {
  const constraints = {
    name: {
      presence: true,
      length: function (value) {
        if (value != undefined) {
          if (value.length == 0)
            return {
              minimum: 1,
              tooShort: '^Пожалуйста, заполните данное поле',
            };

          if (value.length < 2)
            return {
              minimum: 2,
              tooShort: '^Пожалуйста, дозаполните данное поле',
            };
        }
        return false;
      },
    },

    phone: {
      presence: true,
      length: function (value) {
        if (value != undefined) {
          if (value.length == 0)
            return {
              minimum: 1,
              tooShort: '^Пожалуйста, заполните данное поле',
            };

          if (value.length < 18)
            return {
              minimum: 18,
              tooShort: '^Пожалуйста, дозаполните данное поле',
            };
        }
        return false;
      },
    },

    email: {
      presence: true,
      length: {
        minimum: 1,
        tooShort: '^Пожалуйста, заполните данное поле',
      },
      format: function (value) {
        if (value) {
          if (value.indexOf('@') == -1)
            return {
              pattern: '.+?@',
              message: '^В адресе отсутствует символ “@”.',
            };

          if (value.indexOf('@') == value.length - 1)
            return {
              pattern: '@.+',
              message: '^Введите часть адреса после символа “@”',
            };
        }
        return false;
      },
      email: {
        message: '^Пожалуйста, введите корректный email',
      },
    },
  };

  const inputs = {
    name: document.querySelector('.m-feedback__name'),
    phone: document.querySelector('.m-feedback__phone'),
    email: document.querySelector('.m-feedback__mail'),
  };
  const formButton = document.querySelector('.m-feedback__button');
  const form = document.querySelector('.m-feedback__form');

  
  if (document.querySelector('.m-feedback')) {
    IMask(document.querySelector('.m-feedback__phone'), {
      mask: '+{7} (000) 000-00-00',
    });

    new Ripple(formButton);

    //отмена отправки формы на enter
    // form.addEventListener('keydown', function (event) {
    //   if (event.keyCode == 13) {
    //     event.preventDefault();
    //   }
    // });

    form.addEventListener('submit', (e) => {
      let valid;

      for (const key in inputs) {
        valid = getValidate(inputs)
        
        valid ? isValid(inputs[key], valid[key]) : isValid(inputs[key], false);

        inputs[key].oninput = function () {
          valid = getValidate(inputs)
  
          if (key === 'name') {
            this.value = this.value.match(/([а-яА-ЯёЁ]|[a-zA-Z]|\s)+/g);
            this.value = this.value.replace(',', '');
          }
        };

        inputs[key].onfocus = function () {
          inputs[key].classList.remove('--error');
          inputs[key].classList.remove('--correct');
          inputs[key].parentNode.querySelector('.b-input__error-text').classList.remove('--active');
        };

        inputs[key].onblur = function () {
          valid = getValidate(inputs)
          valid ? isValid(this, valid[key]) : isValid(this, false);
        };
      }

      if (Boolean(valid)) {
        e.preventDefault();
        e.stopPropagation();
      }
    }, true)
  }

  function isValid(input, notValid) {
    input.classList.remove('--focus');
    let error = input.parentNode.querySelector('.b-input__error-text');
    
    if (!notValid) {
      input.classList.add('--correct');
      input.classList.remove('--error');
      error.classList.remove('--active');
    } else {
      input.classList.remove('--correct');
      input.classList.add('--error');
      error.classList.add('--active');
      error.innerText = notValid[0];
    }
  }

  function getValidate(inputs) {
    return validate(
      {
        name: inputs.name.value,
        phone: inputs.phone.value,
        email: inputs.email.value,
      }, 
      constraints
    )
  }
}
