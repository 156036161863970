import Swiper, { Navigation, Pagination } from 'swiper';


export default function courses() {
  if (document.querySelector('.course-page') && document.querySelector('[data-scroll-to-form]')) {
    const form=document.querySelector('.course__feedback');
    const marginTop=form.offsetTop - form.previousElementSibling.offsetTop - form.previousElementSibling.offsetHeight

    document.querySelector('[data-scroll-to-form]').onclick=function(){
      window.scrollTo({
        top: form.offsetTop-marginTop,
        behavior: "smooth"
      });
    }
  }
}

if(document.querySelector('.question-btn')) {
  const openPop = document.querySelector('.question-btn').addEventListener("click", function () {
    document.querySelector('.pop-up__over').classList.add("open");
    document.body.style.overflow = 'hidden';
  });
}

if(document.querySelector(".pop-up-cross")) {
  const closeCross = document.querySelector('.pop-up-cross').addEventListener("click", function () {
    document.querySelector('.pop-up__over').classList.remove("open");
    document.body.style.overflow = 'scroll';
  });
}

if(document.querySelector('.prepod-swiper')) {
  var swiper = new Swiper(".prepod-swiper", {
    modules: [Navigation, Pagination],
    slidesPerView: 4,
    spaceBetween: 30,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      // when window width is <= 320px
      300: {
        slidesPerView: 1
      },
      320: {
        slidesPerView: 1
      },
      420: {
        slidesPerView: 2,
        spaceBetween: 10
      },
      480: {
        slidesPerView: 2,
        spaceBetween: 10
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 16
      },
      900: {
        slidesPerView: 2
      },
      1024: {
        slidesPerView: 3
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 16
      },
      1340: {
        slidesPerView: 4,
        spaceBetween: 24
      }
    }
  })

}
