import Scrollbar from 'smooth-scrollbar';

function toggle(element, elementClass = '--active-popup') {
  if (element.classList.contains(elementClass)) {

    element.classList.remove(elementClass);
    document.querySelector('body').classList.remove('--no-scroll');
  } else {
    element.classList.add(elementClass);
  }
}

function initScrollbar(selector, options = { alwaysShowTracks: true }) {
  const elements = document.querySelectorAll(selector);

  if (elements && elements.length > 0) {
    elements.forEach((element) => {
      Scrollbar.init(element, options);
    })
  }
}

function destroyScrollbar(selector) {
  const element = document.querySelector(selector);

  if (element) {
    Scrollbar.destroy(element);
  }
}

export {toggle, initScrollbar, destroyScrollbar}