import $ from 'jquery'; //Работает

import Swiper from 'swiper/bundle';
import { Thumbs } from 'swiper';
import { Fancybox, Carousel, Panzoom } from '@fancyapps/ui'; //Работает
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import "@fancyapps/ui/dist/carousel/carousel.css";

export default function Novosti () {
    const thumbs = document.querySelector('.gallery-thumbs');

    if(thumbs) {
        var galleryThumbs = new Swiper('.gallery-thumbs', {
            spaceBetween: 10,
            slidesPerView: 4,
            loop: true,
            freeMode: true,
            loopedSlides: 5, //looped slides should be the same
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            breakpoints: {
                // when window width is <= 320px
                300: {
                    slidesPerView: 2
                },
                320: {
                    slidesPerView: 2
                },
                420: {
                    slidesPerView: 2
                },
                480: {
                    slidesPerView: 2
                },
                640: {
                    slidesPerView: 2
                },
                900: {
                    slidesPerView: 3
                },
                1024: {
                    slidesPerView: 4
                },
                1200: {
                    slidesPerView: 4
                },
            }
        });
        var galleryTop = new Swiper('.gallery-top', {
            spaceBetween: 10,
            loop: true,
            loopedSlides: 5, //looped slides should be the same
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            thumbs: {
                swiper: galleryThumbs,
            },
        });
    } else {
        if(document.querySelector('.gallery-top')) {
            const galTop = document.querySelector('.gallery-top');
            const swiperWrap = galTop.querySelector('.swiper-wrapper');
            const iFrame = document.querySelector('.yvideo');

            if(swiperWrap) {

                swiperWrap.style.justifyContent="center";
            }
            if(iFrame) {
                iFrame.style.position="static"
                iFrame.style.width="800px"
                iFrame.style.height="500px"
            }
        }
    }
    const slides = document.querySelectorAll('.gallery-top-wrapper');
    
    slides.forEach(function (element) {
        Fancybox.bind('[data-fancybox]')
    })
}
