import '../scss/index.scss';
import "@fancyapps/ui/dist/fancybox/fancybox.css";
// import "@fancyapps/ui/dist/carousel/carousel.css";

//plugins
import $ from 'jquery'; //Работает
import Swiper from 'swiper'; //Работает
import { Fancybox, Carousel, Panzoom } from '@fancyapps/ui'; //Работает
import IMask from 'imask';
import Choices from 'choices.js';
import autoComplete from '@tarekraafat/autocomplete.js';
import ApexCharts from 'apexcharts';
import { rippleEffect, Ripple } from 'data-ripple';

import tabs from './plugins/tabs';
import { source } from './source';
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
//components
import header from './components/header';
import courses from './components/courses';
import course from './components/course';
import feedbackForm from './components/feedback-form';
import regEmail from './components/reg-email';
import { toggle, initScrollbar, destroyScrollbar } from './components/functions';
import { stringify } from 'postcss';
import oNas from './components/o-nas';
import feedback from './components/feedback';
import Novosti from './components/novosti';

import {initApi as initModalsApi, initModalsV2} from './components/modal-v2';

const toTop = document.querySelector('.b-scrollTopButton');
const gradient = document.querySelector('.searchForm__gradient');

initModalsApi();

document.addEventListener('DOMContentLoaded', () => {
  initModalsV2();
  document.querySelectorAll('.sendStatus').forEach(function (popup) {
    const closeButton = popup.querySelector('.sendStatus__close');
    if (closeButton) {
      closeButton.onclick = function () {
        popup.classList.remove('--active-send');
      };
    }
  });

  function init() {
    new Ripple(document.querySelector('[data-ripple]', {
       fadeOutOnClick: true,
    }));
    initScrollbar('.about__docs-container');
    initScrollbar('.direction__container');
    initScrollbar('.menu-mobile');

    //teacher
    initScrollbar('.teacher-page .teacher__cards .m-courses__container');

    //main
    initScrollbar('.main-page .enrollment .m-courses__container');
    // initScrollbar('.main-page .closes-courses .m-courses__container');
    // initScrollbar('.main-page .popular-courses .m-courses__container');

    // test
    initScrollbar('.test-page .m-courses__container');

    initScrollbar('[data-scrollbar-content]');
    header();
    oNas();
    feedback();
    Novosti();
    courses();
    course();
    feedbackForm();
    regEmail();
  }

  init();
});

if (toTop) {
  toTop.onclick = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };
}

const autoCompleteJS = new autoComplete({
  selector: '.search__input',
  data: {
    src: [],
    keys: ['name'],
  },

  resultItem: {
    tag: 'a',
    highlight: true,
    element: (item, data) => {
      item.setAttribute('href', data.value.url);
    },
  },

  events: {
    input: {
      open() {
        document.querySelector('.searchForm').classList.add('--active');

        initScrollbar('.searchForm__content', {
          alwaysShowTracks: true,
        });

        if (autoCompleteJS.list.querySelectorAll('a').length > 5) {
          gradient.classList.add('--active');
        } else {
          gradient.classList.remove('--active');
        }

        const scroll_content = document.querySelector('.scroll-content');
        const searchForm = document.querySelector('.searchForm__content ');
        searchForm.onwheel = function () {
          let props = scroll_content.style.transform.split(', ');
          for (let i = 0; i < props.length; i++) {
            props[i] = props[i].replace(/.*\(|,\s|\)|px/g, '');
          }

          let minOffset = searchForm.clientHeight - scroll_content.clientHeight;

          if (props.length === 3 && props[1] == minOffset) {
            gradient.classList.remove('--active');
          } else {
            gradient.classList.add('--active');
          }
        };
      },
      close() {
        document.querySelector('.searchForm').classList.remove('--active');
      },
    },
  },

  resultsList: {
    tag: 'div',
    destination: '.searchForm__content',
    position: 'afterbegin',
    class: 'searchForm__container',
    tabSelect: false,
    element: (list, data) => {
      const message = document.createElement('span');
      if (!data.results.length) {
        message.setAttribute('class', 'no_result');
        message.innerHTML = `<span>Результаты не найдены</span>`;
        list.prepend(message);
        gradient.classList.remove('--active');
      }
    },
    noResults: true,
    maxResults: 100,
  },
});

const searchInput = document.querySelector('.search__input');
if (searchInput && autoCompleteJS) {
  window.searchInputTimeout = false;
  searchInput.onkeyup = function () {
    if (window.searchInputTimeout) {
      clearTimeout(window.searchInputTimeout);
    }
    window.searchInputTimeout = setTimeout(function () {
      const searchValue = searchInput.value;
      if(searchValue.length > 3){
        const request = new XMLHttpRequest();
        const action = searchInput.getAttribute('data-action') + '?q="' + searchValue + '"';
        request.open('get', action, false);
        try {
          request.send();
          if (request.status == 200) {
            const data = JSON.parse(request.responseText);
            autoCompleteJS.data.src = data;
            autoCompleteJS.start();
          }
          else {
            console.log('Ошибка запроса: ' + request.status);
          }
        }
        catch (error) {
          console.log(error);
        }
      }
    }, 500);
  }
}


const coursesBtn = document.querySelectorAll('.js-showCourses');

coursesBtn.forEach((btn) => {
  const block = btn.parentNode;
  const hiddenBlock = block.querySelector('.cards-3-2.--hidden');
  const height = hiddenBlock.clientHeight;
  hiddenBlock.style.height = 0 + 'px';
  btn.onclick = function () {
    block.classList.add('--active');
    hiddenBlock.style.height = height + 'px';
    hiddenBlock.style.overflow = 'visible';
    btn.style.display = 'none';

    setTimeout(() => {
      hiddenBlock.style.height = 'auto';
    }, 200);
  };
});



if (document.querySelector('.accordion')) {

  let accordionItem = document.querySelectorAll('.accordion__item');


  for (const elem of accordionItem) {
    let title = elem.querySelector('.accordion__title');
    let wrapper = elem.querySelector('.accordion__block');

    if(wrapper) {
      wrapper.style.height = 0;

      document.querySelector('.header').onclick = function () {
        elem.classList.remove('--active-popup');
      };

      title.onclick = function () {
        let listHeight = wrapper.querySelector('ul').clientHeight;

        if (!elem.classList.contains('--active')) {
          elem.classList.add('--active');
          wrapper.style.height = listHeight + 'px';

          if (document.querySelector('.teachers__accordion') || document.querySelector('.courses__accordion')) {
            initScrollbar('.accordion__block', {
              alwaysShowTracks: false,
            });
          }
        } else {
          wrapper.style.height = 0;
          elem.classList.remove('--active');
        }
      };
    }
  }
}


