const source = [
  {
    name: '1Курс налогообложения и отчетности крестьянского (фермерского) хозяйства с применением цифровых средств',
    url: 'https://tarekraafat.github.io/autoComplete.js/#/configuration?id=tag-optional-1',
  },
  {
    name: '1Курс налогообложения и отчетности крестьянского (фермерского) хозяйства с применением цифровых средств',
    url: 'https://tarekraafat.github.io/autoComplete.js/#/configuration?id=tag-optional-1',
  },
  {
    name: '1Курс налогообложения и отчетности крестьянского (фермерского) хозяйства с применением цифровых средств',
    url: 'https://tarekraafat.github.io/autoComplete.js/#/configuration?id=tag-optional-1',
  },
  {
    name: '1Курс налогообложения и отчетности крестьянского (фермерского) хозяйства с применением цифровых средств',
    url: 'https://tarekraafat.github.io/autoComplete.js/#/configuration?id=tag-optional-1',
  },
  {
    name: '1Курс налогообложения и отчетности крестьянского (фермерского) хозяйства с применением цифровых средств',
    url: 'https://tarekraafat.github.io/autoComplete.js/#/configuration?id=tag-optional-1',
  },
  {
    name: '1Курс налогообложения и отчетности крестьянского (фермерского) хозяйства с применением цифровых средств',
    url: 'https://tarekraafat.github.io/autoComplete.js/#/configuration?id=tag-optional-1',
  },
  {
    name: '1Курс налогообложения и отчетности крестьянского (фермерского) хозяйства с применением цифровых средств',
    url: 'https://tarekraafat.github.io/autoComplete.js/#/configuration?id=tag-optional-1',
  },
  {
    name: '1Курс налогообложения и отчетности крестьянского (фермерского) хозяйства с применением цифровых средств',
    url: 'https://tarekraafat.github.io/autoComplete.js/#/configuration?id=tag-optional-1',
  },
  {
    name: '2Курс налогообложения и отчетности крестьянского (фермерского) хозяйства с применением цифровых средств',
    url: 'https://tarekraafat.github.io/autoComplete.js/#/configuration?id=tag-optional-1',
  },
];

export { source };
