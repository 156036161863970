import noUiSlider from 'nouislider';
import autoComplete from '@tarekraafat/autocomplete.js';
import Scrollbar from 'smooth-scrollbar';
import { toggle } from './functions';

export default function courses() {
  if (document.querySelector('.courses-page')) {
    const rangeCount = [
      {
        range: {
          min: 100,
          max: 10000,
        },
        step: 100,
      },
      {
        range: {
          min: 20,
          max: 124,
        },
        step: 1,
      },
      {
        range: {
          min: 1,
          max: 4,
        },
        step: 1,
      },
      {
        range: {
          min: 1,
          max: 12,
        },
        step: 1,
      },
    ];

    const ranges = document.querySelectorAll('.filter__range');
    const filter = document.querySelector('.filter');

    /*const checkBoxes = {
      time: {
        checkbox: document.querySelector('.js-checkBox-duration'),
        item: filter.querySelector('.hours'),
        itemHidden: filter.querySelector('.months'),
      },
      price: {
        checkbox: document.querySelector('.js-checkBox-price'),
        item: filter.querySelector('.price'),
      },
    };*/

    const filterCheckBox = filter.querySelectorAll('.b-checkBox');
    const filterAdd = filter.querySelector('.filter__add');
    const filterBtn = filterAdd.querySelector('.b-button');
    const filterRemove = filter.querySelectorAll('.filter__remove');
    const filterToggle = document.querySelectorAll('.b-toggle');
    const filterButton = document.querySelector('.courses__filter-btn');
    const filterBlock = document.querySelector('.courses__filter');
    const layout = document.querySelector('.layout');
    const filterClose = document.querySelector('.filter__close');

    filterBtn.disabled = true;
    filterCheckBox.forEach((checkBox) => {
      checkBox.onchange = function () {
        showRemoveBtn(checkBox);
        
      };
    });

    filterToggle.forEach((toggle) => {
      toggle.onchange = function () {
        showRemoveBtn(toggle);
      };
    });

    filterRemove.forEach(btn=>{

      btn.onclick=function(){
        if (document.querySelector('.filter__remove.-desk')) {
          document.querySelector('.filter__remove.-desk').classList.remove('--active')
        }

        document.querySelector('.courses__filter').style.paddingBottom='154px'
        filterAdd.classList.remove('--active');
        filterBtn.disabled = true;
  
        filterCheckBox.forEach((checkBox) => {
          checkBox.classList.contains('--checked') ? checkBox.classList.remove('--checked') : '';
        });
  
        filterToggle.forEach((toggle) => {
          toggle.classList.contains('--checked') ? toggle.classList.remove('--checked') : '';
        });
  
        /* harmonyfor (const key in checkBoxes) {
          checkBoxes[key].item.style.display = 'block';
          checkBoxes[key].itemHidden ? (checkBoxes[key].itemHidden.style.display = 'none') : '';
        }*/
  
        ranges.forEach((range, i) => {
          range.noUiSlider.set([rangeCount[i].range.min,rangeCount[i].range.max]);
        })
      }
    })

    ranges.forEach((range, i) => {
      noUiSlider.create(range, {
        start: [0, rangeCount[i].range.max],
        connect: true,
        step: rangeCount[i].step,
        margin: 1,
        range: rangeCount[i].range,
      });

      range.noUiSlider.on('update', function (values, handle) {
        const max = range.parentNode.querySelector('.max');
        const min = range.parentNode.querySelector('.min');

        if (handle) {
          max.innerHTML = Math.floor(values[handle]);

          if (max.innerHTML < rangeCount[i].range.max) {
            showRemoveBtn(range);
          }
        } else {
          min.innerHTML = Math.floor(values[handle]);
          if (min.innerHTML > rangeCount[i].range.min) {
            showRemoveBtn(range);
          }
        }
      });
    });

    /*for (const key in checkBoxes) {
      checkBoxes[key].item.style.display = 'block';

      checkBoxes[key].checkbox.onchange = function () {
        if (checkBoxes[key].item.style.display == 'block') {
          checkBoxes[key].item.style.display = 'none';
          checkBoxes[key].itemHidden ? (checkBoxes[key].itemHidden.style.display = 'block') : '';
        } else {
          checkBoxes[key].item.style.display = 'block';
          checkBoxes[key].itemHidden ? (checkBoxes[key].itemHidden.style.display = 'none') : '';
        }
      };
    }*/

    filterButton.onclick = function () {
      Scrollbar.init(document.querySelector('.filter__wrapper'), {
        alwaysShowTracks: true,
      });

      filterBlock.classList.add('--active');
      layout.classList.add('--active');
      document.querySelector('body').classList.add('--no-scroll');
    };

    layout.onclick = function () {
      filterBlock.classList.remove('--active');
      layout.classList.remove('--active');
      layout.classList.remove('--active-popup');
      document.querySelector('body').classList.remove('--no-scroll');
      document.querySelector('.search').classList.remove('--active-popup')
      document.querySelector('.menu-mobile').classList.remove('open')
      document.querySelector('.header__wrapper').classList.remove('--active')
      Scrollbar.destroy(document.querySelector('.filter__wrapper'));
    };

    filterClose.onclick = function () {
      filterBlock.classList.remove('--active');
      layout.classList.remove('--active');
      document.querySelector('body').classList.remove('--no-scroll');
      Scrollbar.destroy(document.querySelector('.filter__wrapper'));
    };
  }
}

function showRemoveBtn(element) {
  const filterAdd = document.querySelector('.filter__add');
  const filterBtn = filterAdd.querySelector('.b-button');
  const checkBox = element.querySelector('input');
  
  document.querySelector('.courses__filter').style.paddingBottom='200px'
  filterAdd.classList.contains('--active') ? '' : filterAdd.classList.add('--active');

  if (document.querySelector('.filter__remove.-desk')) {
    const filterRemoveDesk=document.querySelector('.filter__remove.-desk');
    filterRemoveDesk.classList.contains('--active')?'':filterRemoveDesk.classList.add('--active')
  }

  if (element.classList.contains('--checked')) {
    element.classList.remove('--checked');
    // checkBox.checked = false;
  } else {
    element.classList.add('--checked');
    // checkBox.checked = true;
  }
  filterBtn.disabled = false;
}
